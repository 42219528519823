import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { theme } from '../../constants';

const breakpoints = [
  `${theme.BreakpointMobile}px`,
  `${theme.BreakpointMobileXl}px`,
  `${theme.BreakpointTablet}px`,
  `${theme.BreakpointTabletXl}px`,
  `${theme.BreakpointLaptop}px`,
  `${theme.BreakpointLaptopXl}px`,
  `${theme.BreakpointDesktop}px`,
  `${theme.BreakpointDesktopXl}px`,
  `${theme.BreakpointTv}px`,
];

breakpoints.mobile = `${theme.BreakpointMobile}px`;
breakpoints.tablet = `${theme.BreakpointTablet}px`;
breakpoints.laptop = `${theme.BreakpointLaptop}px`;
breakpoints.desktop = `${theme.BreakpointDesktop}px`;
breakpoints.tv = `${theme.BreakpointTv}px`;

const darkColors = {
  bg100: theme.ColorDarkBg100,
  bg80: theme.ColorDarkBg80,
  bg60: theme.ColorDarkBg60,
  bg40: theme.ColorDarkBg40,
  bg20: theme.ColorDarkBg20,
  black100: theme.ColorDarkBlack100,
  black80: theme.ColorDarkBlack80,
  black60: theme.ColorDarkBlack60,
  contrast100: theme.ColorDarkContrast100,
  contrast80: theme.ColorDarkContrast80,
  contrast60: theme.ColorDarkContrast60,
  contrast40: theme.ColorDarkContrast40,
  contrast20: theme.ColorDarkContrast20,
  error: theme.ColorDarkError,
  info: theme.ColorDarkInfo,
  primary: theme.ColorDarkPrimary,
  secondary: theme.ColorDarkSecondary,
  success: theme.ColorDarkSuccess,
  warning: theme.ColorDarkWarning,
  white100: theme.ColorDarkWhite100,
  white80: theme.ColorDarkWhite80,
  white60: theme.ColorDarkWhite60,
};

const lightColors = {
  bg100: theme.ColorLightBg100,
  bg80: theme.ColorLightBg80,
  bg60: theme.ColorLightBg60,
  bg40: theme.ColorLightBg40,
  bg20: theme.ColorLightBg20,
  black100: theme.ColorLightBlack100,
  black80: theme.ColorLightBlack80,
  black60: theme.ColorLightBlack60,
  contrast100: theme.ColorLightContrast100,
  contrast80: theme.ColorLightContrast80,
  contrast60: theme.ColorLightContrast60,
  contrast40: theme.ColorLightContrast40,
  contrast20: theme.ColorLightContrast20,
  error: theme.ColorLightError,
  info: theme.ColorLightInfo,
  primary: theme.ColorLightPrimary,
  secondary: theme.ColorLightSecondary,
  success: theme.ColorLightSuccess,
  warning: theme.ColorLightWarning,
  white100: theme.ColorLightWhite100,
  white80: theme.ColorLightWhite80,
  white60: theme.ColorLightWhite60,
};

const darkTheme = {
  breakpoints,
  colors: darkColors,
  mode: 'dark',
};

const lightTheme = {
  breakpoints,
  colors: lightColors,
  mode: 'light',
};

function ThemeProvider({ children }) {
  const [currentTheme, setCurrentTheme] = React.useState('light');
  React.useEffect(() => {
    const themeQuery = window.matchMedia('(prefers-color-scheme: light)');
    setCurrentTheme(themeQuery.matches ? 'light' : 'dark');
    themeQuery.addEventListener('change', ({ matches }) => {
      setCurrentTheme(matches ? 'light' : 'dark');
    });
  }, []);
  return (
    <StyledThemeProvider theme={currentTheme === 'light' ? lightTheme : darkTheme}>
      {children}
    </StyledThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
