const enMessages = {
  'HomePage:Text:Heading1': 'Boost your brand with impactful audiovisual content',
  'HomePage:Text:Heading2': 'Our Services',
  'HomePage:Text:Heading3': 'Our Process',
  'HomePage:Text:Heading4': 'Why Choose Us?',
  'HomePage:Text:OurProcess': `
    <h4>1. Discovery:</h4>
    <p>We know your brand and what you want to communicate in depth.</p>
    <h4>2. Conceptualization:</h4>
    <p>We develop creative ideas aligned with your objectives.</p>
    <h4>3. Production:</h4>
    <p>We capture and create high-quality visual content.</p>
    <h4>4. Postproduction:</h4>
    <p>We edit and refine every detail to ensure an impeccable result.</p>
    <h4>5. Delivery and Publication:</h4>
    <p>We deliver content ready to share and publish on your networks.</p>
  `,
  'HomePage:Text:OurServices': `
    <h4>Video Production:</h4>
    <p>From the idea to the final edit, we produce audiovisual content tailored to your needs and objectives.</p>
    <h4>Content Strategy:</h4>
    <p>We plan and execute content strategies that resonate with your audience and increase your online presence.</p>
    <h4>Social Media Optimization:</h4>
    <p>We optimize each video to achieve maximum impact on the most important social platforms.</p>
  `,
  'HomePage:Text:WhyChooseUs': `
    <h4>Unlimited Creativity:</h4>
    <p>We are passionate about telling visual stories that capture attention and excite.</p>
    <h4>Adaptability:</h4>
    <p>We adapt to the latest trends and changes in social media to keep your content always relevant.</p>
    <h4>Personalized Approach:</h4>
    <p>Each project is unique. We make sure that your vision is reflected in each frame.</p>
  `,
  'HomePage:Text:Subheading1': 'We create videos that capture the essence of your business and connect with your audience on social media',
  'MainFooter:Link:Home': 'Home',
  'MainFooter:Link:PrivacyNotice': 'Privacy Notice',
  'MainFooter:Text:Rights': 'Cuervo Creativo. All rights reserved',
  'MainHeader:Link:Home': 'Home',
  'MainHeader:Link:OurProcess': 'Our Process',
  'MainHeader:Link:Services': 'Services',
  'MainHeader:Link:WhyChooseUs': 'Why Choose Us?',
  'NotFoundPage:Alt:Error': 'Error 404',
  'NotFoundPage:Heading:Error': 'Error 404: Page not found',
  'NotFoundPage:Description:Error': 'The page you\'re looking for is not available. Please check the URL and try again.',
};

export default enMessages;
