const esMessages = {
  'HomePage:Text:Heading1': 'Potencia tu marca con contenido audiovisual impactante',
  'HomePage:Text:Heading2': 'Nuestros servicios',
  'HomePage:Text:Heading3': 'Nuestro Proceso',
  'HomePage:Text:Heading4': '¿Por qué Elegirnos?',
  'HomePage:Text:OurProcess': `
    <h4>1. Descubrimiento:</h4>
    <p>Conocemos a fondo tu marca y lo que quieres comunicar.</p>
    <h4>2. Conceptualización:</h4>
    <p>Desarrollamos ideas creativas alineadas con tus objetivos.</p>
    <h4>3. Producción:</h4>
    <p>Capturamos y creamos contenido visual de alta calidad.</p>
    <h4>4. Postproducción:</h4>
    <p>Editamos y refinamos cada detalle para asegurar un resultado impecable.</p>
    <h4>5. Entrega y Publicación:</h4>
    <p>Entregamos el contenido listo para compartir y publicarlo en tus redes.</p>
  `,
  'HomePage:Text:OurServices': `
    <h4>Producción de Video:</h4>
    <p>Desde la idea hasta la edición final, producimos contenido audiovisual adaptado a tus necesidades y objetivos.</p>
    <h4>Estrategia de Contenido:</h4>
    <p>Planificamos y ejecutamos estrategias de contenido que resuenan con tu audiencia y aumentan tu presencia online.</p>
    <h4>Optimización para Redes Sociales:</h4>
    <p>Optimizamos cada video para que alcance el máximo impacto en las plataformas sociales más importantes.</p>
  `,
  'HomePage:Text:WhyChooseUs': `
    <h4>Creatividad sin Límites:</h4>
    <p>Nos apasiona contar historias visuales que capturen la atención y emocionen.</p>
    <h4>Adaptabilidad:</h4>
    <p>Nos adaptamos a las últimas tendencias y cambios en las redes sociales para mantener tu contenido siempre relevante.</p>
    <h4>Enfoque Personalizado:</h4>
    <p>Cada proyecto es único. Nos aseguramos de que tu visión se refleje en cada frame.</p>
  `,
  'HomePage:Text:Subheading1': 'Creamos videos que captan la esencia de tu negocio y conectan con tu audiencia en redes sociales',
  'MainFooter:Link:Home': 'Inicio',
  'MainFooter:Link:PrivacyNotice': 'Aviso de Privacidad',
  'MainFooter:Text:Rights': 'Cuervo Creativo. Todos los derechos reservados',
  'MainHeader:Link:Home': 'Inicio',
  'MainHeader:Link:OurProcess': 'Nuestro Proceso',
  'MainHeader:Link:Services': 'Services',
  'MainHeader:Link:WhyChooseUs': '¿Por qué Elegirnos?',
  'NotFoundPage:Alt:Error': 'Error 404',
  'NotFoundPage:Heading:Error': 'Error 404: Página no encontrada',
  'NotFoundPage:Description:Error': 'La página que estás buscando no está disponible. Por favor, verifica la URL e intenta nuevamente.',
};

export default esMessages;
