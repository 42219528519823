import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import languages from '../../constants/languages';
import en from './messages/en';
import es from './messages/es';
import getCurrentLangKey from '../../utils/getCurrentLangKey';

const messages = {
  es,
  en,
};

function IntlProvider({ children }) {
  const location = useLocation();
  const { langs, defaultLangKey } = languages;
  const langKey = getCurrentLangKey(langs, defaultLangKey, location.pathname);
  return (
    <ReactIntlProvider locale={langKey} messages={messages[langKey]}>
      {children}
    </ReactIntlProvider>
  );
}

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntlProvider;
